import gql from 'graphql-tag';
import { useMutation } from 'urql';

import {
  ResendVerificationEmail,
  ResendVerificationEmailVariables,
} from './__generated__/ResendVerificationEmail';

const RESEND_VERIFICATION_EMAIL_MUTATION = gql`
  mutation ResendVerificationEmail($playerId: ID!) {
    resendVerificationEmail(playerId: $playerId) {
      playerId
    }
  }
`;

export default function useResendVerificationEmail() {
  return useMutation<ResendVerificationEmail, ResendVerificationEmailVariables>(
    RESEND_VERIFICATION_EMAIL_MUTATION,
  );
}
