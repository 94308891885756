import React from 'react';

import { Date as DateComponent } from '@/components';
import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import {
  PlayerInfoBox,
  PlayerInfoBox_player_selfExclusionDetails,
} from './__generated__/PlayerInfoBox';
import { SanityPlayerInfoBlockFragment } from './__generated__/SanityPlayerInfoBlockFragment';

const isIndefinite = (
  details: Nullable<PlayerInfoBox_player_selfExclusionDetails>,
) => {
  if (!details) {
    return false;
  }

  return (
    !details.willBeCancelledAt && !details.exclusionEndsAt && !details.pending
  );
};

export const PlayerSelfExclusionDetails = ({
  block,
  data,
}: {
  block: SanityPlayerInfoBlockFragment;
  data: PlayerInfoBox | undefined;
}) => {
  const { t } = useTranslate();
  const details = data?.player?.selfExclusionDetails;
  return (
    <>
      {details?.pending && (
        <div>
          {t(block.pendingEnding)}{' '}
          <DateComponent copyable date={details?.pending?.exclusionEndsAt} />
        </div>
      )}

      {isIndefinite(details) && <div>{t(block.indefinite)}</div>}

      {!details?.willBeCancelledAt && details?.exclusionEndsAt && (
        <div>
          {t(block.excludedUntil)}{' '}
          <DateComponent copyable date={details?.exclusionEndsAt} />
        </div>
      )}

      {details?.willBeCancelledAt && (
        <div>
          {t(block.willBeCancelledAt)}{' '}
          <DateComponent copyable date={details.willBeCancelledAt} />
        </div>
      )}
      {!details && '-'}
    </>
  );
};
