import { startCase } from 'lodash';
import React from 'react';

import {
  ControlledModal,
  Date as DateComponent,
  InlineIconButton,
} from '@/components';
import { EditIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { PlayerAccountStatusForm } from '@/forms';
import { AccountStatusEnum } from '@/globalTypes';
import { PlayerInfoBox } from './__generated__/PlayerInfoBox';
import { SanityPlayerInfoBlockFragment } from './__generated__/SanityPlayerInfoBlockFragment';

export const AccountStatus = ({
  data,
  block,
}: {
  block: SanityPlayerInfoBlockFragment;
  data: PlayerInfoBox | undefined;
}) => {
  const { t } = useTranslate();

  const description =
    data?.player?.accountStatus.status === AccountStatusEnum.Open
      ? t(block.accountStatusOpen)
      : `${t(block.accountStatusClosed)} (${startCase(
          data?.player?.accountStatus.cause || '',
        )})`;

  return (
    <>
      {description}
      {data?.player?.shouldReopenAt && (
        <>
          <br />
          {t(block.accountWillReopen)}{' '}
          <DateComponent copyable date={data?.player?.shouldReopenAt} />
        </>
      )}
      <ControlledModal
        content={
          data?.player.id ? (
            <PlayerAccountStatusForm playerId={data?.player.id} />
          ) : null
        }
      >
        <InlineIconButton>
          <EditIcon />
        </InlineIconButton>
      </ControlledModal>
    </>
  );
};
